export default {
  AC: 'アセンション島',
  AD: 'アンドラ',
  AE: 'アラブ首長国連邦',
  AF: 'アフガニスタン',
  AG: 'アンティグアバーブーダ',
  AI: 'アンギラ',
  AL: 'アルバニア',
  AM: 'アルメニア',
  AN: 'オランダ領アンティル諸島',
  AO: 'アンゴラ',
  AQ: '南極大陸',
  AR: 'アルゼンチン',
  AS: 'アメリカンサモア',
  AT: 'オーストリア',
  AU: 'オーストラリア',
  AW: 'アルバ',
  AX: 'オーランド諸島',
  AZ: 'アゼルバイジャン',
  BA: 'ボスニア・ヘルツェゴビナ',
  BB: 'バルバドス',
  BD: 'バングラデシュ',
  BE: 'ベルギー',
  BF: 'ブルキナファソ',
  BG: 'ブルガリア',
  BH: 'バーレーン',
  BI: 'ブルンジ',
  BJ: 'ベニン',
  BL: 'サンバセレミー',
  BM: 'バミューダ',
  BN: 'ブルネイ',
  BO: 'ボリビア',
  BQ: 'カリブ海のオランダ領',
  BR: 'ブラジル',
  BS: 'バハマ',
  BT: 'ブータン',
  BV: 'ブーベ島',
  BW: 'ボツワナ',
  BY: 'ベラルーシ',
  BZ: 'ベリーズ',
  CA: 'カナダ',
  CC: 'ココス[キーリング]諸島',
  CD: 'コンゴ - キンシャサ',
  CF: '中央アフリカ共和国',
  CG: 'コンゴ - ブラザビル',
  CH: 'スイス',
  CI: 'コートジボワール',
  CK: 'クック諸島',
  CL: 'チリ',
  CM: 'カメルーン',
  CN: '中国',
  CO: 'コロンビア',
  CP: 'クリッパートン島',
  CR: 'コスタリカ',
  CU: 'キューバ',
  CV: 'カーボベルデ共和国',
  CW: 'キュラソー島',
  CX: 'クリスマス島',
  CY: 'キプロス',
  CZ: 'チェコ共和国',
  DE: 'ドイツ',
  DG: 'ディエゴガルシア',
  DJ: 'ジブチ',
  DK: 'デンマーク',
  DM: 'ドミニカ',
  DO: 'ドミニカ共和国',
  DZ: 'アルジェリア',
  EA: 'セウタとメリリャ',
  EC: 'エクアドル',
  EE: 'エストニア',
  EG: 'エジプト',
  EH: '西サハラ',
  ER: 'エリトリア',
  ES: 'スペイン',
  ET: 'エチオピア',
  EU: '欧州連合',
  FI: 'フィンランド',
  FJ: 'フィジー',
  FK: 'フォークランド諸島',
  FM: 'ミクロネシア',
  FO: 'フェロー諸島',
  FR: 'フランス',
  GA: 'ガボン',
  GB: 'イギリス',
  GD: 'グレナダ',
  GE: 'ジョージア',
  GF: 'フランス領ギアナ',
  GG: 'ガーンジー島',
  GH: 'ガーナ',
  GI: 'ジブラルタル',
  GL: 'グリーンランド',
  GM: 'ガンビア',
  GN: 'ギニア',
  GP: 'グアドループ',
  GQ: '赤道ギニア',
  GR: 'ギリシャ',
  GS: 'サウスジョージア州とサウス・サンドイッチ諸島',
  GT: 'グアテマラ',
  GU: 'グアム',
  GW: 'ギニアビサウ',
  GY: 'ガイアナ',
  HK: '香港特別自治区',
  HM: 'ハード島・マクドナルド諸島',
  HN: 'ホンジュラス',
  HR: 'クロアチア',
  HT: 'ハイチ',
  HU: 'ハンガリー',
  IC: 'カナリア諸島',
  ID: 'インドネシア',
  IE: 'アイルランド',
  IL: 'イスラエル',
  IM: 'マン島',
  IN: 'インド',
  IO: 'イギリス領インド洋地域',
  IQ: 'イラク',
  IR: 'イラン',
  IS: 'アイスランド',
  IT: 'イタリア',
  JE: 'ジャージー',
  JM: 'ジャマイカ',
  JO: 'ヨルダン',
  JP: '日本',
  KE: 'ケニア',
  KG: 'キルギス共和国',
  KH: 'カンボジア',
  KI: 'キリバス',
  KM: 'コモロ',
  KN: 'セントクリストファー・ネイビス',
  KP: '北朝鮮',
  KR: '韓国',
  KW: 'クウェート',
  KY: 'ケイマン諸島',
  KZ: 'カザフスタン',
  LA: 'ラオス',
  LB: 'レバノン',
  LC: 'セントルシア',
  LI: 'リヒテンシュタイン',
  LK: 'スリランカ',
  LR: 'リベリア',
  LS: 'レソト',
  LT: 'リトアニア',
  LU: 'ルクセンブルク',
  LV: 'ラトビア',
  LY: 'リビア',
  MA: 'モロッコ',
  MC: 'モナコ',
  MD: 'モルドバ',
  ME: 'モンテネグロ',
  MF: 'サンマルタン',
  MG: 'マダガスカル',
  MH: 'マーシャル諸島',
  MK: 'マケドニア',
  ML: 'マリ',
  MM: 'ミャンマー[ビルマ]',
  MN: 'モンゴル',
  MO: '中国マカオ',
  MP: '北マリアナ諸島',
  MQ: 'マルティニーク島',
  MR: 'モーリタニア',
  MS: 'モントセラト',
  MT: 'マルタ',
  MU: 'モーリシャス',
  MV: 'モルディブ',
  MW: 'マラウイ',
  MX: 'メキシコ',
  MY: 'マレーシア',
  MZ: 'モザンビーク',
  NA: 'ナミビア',
  NC: 'ニューカレドニア',
  NE: 'ニジェール',
  NF: 'ノーフォーク島',
  NG: 'ナイジェリア',
  NI: 'ニカラグア',
  NL: 'オランダ',
  NO: 'ノルウェー',
  NP: 'ネパール',
  NR: 'ナウル',
  NU: 'ニウエ',
  NZ: 'ニュージーランド',
  OM: 'オマーン',
  PA: 'パナマ',
  PE: 'ペルー',
  PF: 'フランス領ポリネシア',
  PG: 'パプアニューギニア',
  PH: 'フィリピン',
  PK: 'パキスタン',
  PL: 'ポーランド',
  PM: 'サンピエール・ミケロン',
  PN: 'ピトケアン諸島',
  PR: 'プエルトリコ',
  PS: 'パレスチナ領土',
  PT: 'ポルトガル',
  PW: 'パラオ',
  PY: 'パラグアイ',
  QA: 'カタール',
  QO: 'オセアニア',
  RE: 'レユニオン',
  RO: 'ルーマニア',
  RS: 'セルビア',
  RU: 'ロシア',
  RW: 'ルワンダ',
  SA: 'サウジアラビア',
  SB: 'ソロモン諸島',
  SC: 'セイシェル',
  SD: 'スーダン',
  SE: 'スウェーデン',
  SG: 'シンガポール',
  SH: 'セントヘレナ',
  SI: 'スロベニア',
  SJ: 'スヴァールバル・ヤン・マイエン',
  SK: 'スロバキア',
  SL: 'シエラレオネ',
  SM: 'サンマリノ',
  SN: 'セネガル',
  SO: 'ソマリア',
  SR: 'スリナム',
  SS: '南スーダン',
  ST: 'サントメとプリンシペ',
  SV: 'エルサルバドル',
  SX: 'シントマールテン',
  SY: 'シリア',
  SZ: 'スワジランド',
  TA: 'トリスタンダクーニャ',
  TC: 'タークスカイコス諸島',
  TD: 'チャド',
  TF: 'フランス領南方領土',
  TG: 'トーゴ',
  TH: 'タイ',
  TJ: 'タジキスタン',
  TK: 'トケラウ',
  TL: 'ティモール・レスト',
  TM: 'トルクメニスタン',
  TN: 'チュニジア',
  TO: 'トンガ',
  TR: 'トルコ',
  TT: 'トリニダード・トバゴ',
  TV: 'ツバル',
  TW: '台湾',
  TZ: 'タンザニア',
  UA: 'ウクライナ',
  UG: 'ウガンダ',
  UM: 'アメリカ合衆国の海外領土',
  US: 'アメリカ',
  UY: 'ウルグアイ',
  UZ: 'ウズベキスタン',
  VA: 'バチカン',
  VC: 'セントビンセント・グレナディーン',
  VE: 'ベネズエラ',
  VG: 'イギリス領ヴァージン諸島',
  VI: '米領バージン諸島',
  VN: 'ベトナム',
  VU: 'バヌアツ',
  WF: 'ウォリス・フツナ',
  WS: 'サモア',
  YE: 'イエメン',
  YT: 'マヨット',
  ZA: '南アフリカ',
  ZM: 'ザンビア',
  ZW: 'ジンバブエ',
  ZZ: '不明地域',
};
