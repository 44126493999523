export default {
  AC: 'Pulau Ascension',
  AD: 'Andorra',
  AE: 'Emiriah Arab Bersatu',
  AF: 'Afghanistan',
  AG: 'Antigua dan Barbuda',
  AI: 'Anguilla',
  AL: 'Albania',
  AM: 'Armenia',
  AN: 'Antillen Belanda',
  AO: 'Angola',
  AQ: 'Antartika',
  AR: 'Argentina',
  AS: 'Samoa Amerika',
  AT: 'Austria',
  AU: 'Australia',
  AW: 'Aruba',
  AX: 'Kepulauan Aland',
  AZ: 'Azerbaijan',
  BA: 'Bosnia dan Herzegovina',
  BB: 'Barbados',
  BD: 'Bangladesh',
  BE: 'Belgium',
  BF: 'Burkina Faso',
  BG: 'Bulgaria',
  BH: 'Bahrain',
  BI: 'Burundi',
  BJ: 'Benin',
  BL: 'Saint Barthélemy',
  BM: 'Bermuda',
  BN: 'Brunei Darussalam',
  BO: 'Bolivia',
  BQ: 'Caribbean Belanda',
  BR: 'Brazil',
  BS: 'Bahamas',
  BT: 'Bhutan',
  BV: 'Pulau Bouvet',
  BW: 'Botswana',
  BY: 'Belarus',
  BZ: 'Belize',
  CA: 'Kanada',
  CC: 'Kepulauan Cocos [Keeling]',
  CD: 'Congo - Kinshasa',
  CF: 'Republik Afrika Tengah',
  CG: 'Congo - Brazzaville',
  CH: 'Switzerland',
  CI: 'Côte d’Ivoire',
  CK: 'Kepulauan Cook',
  CL: 'Chile',
  CM: 'Kamerun',
  CN: 'China',
  CO: 'Colombia',
  CP: 'Kepulauan Clipperton',
  CR: 'Costa Rica',
  CU: 'Cuba',
  CV: 'Tanjung Verde',
  CW: 'Curaçao',
  CX: 'Pulau Krimas',
  CY: 'Cyprus',
  CZ: 'Republik Czech',
  DE: 'Jerman',
  DG: 'Diego Garcia',
  DJ: 'Djibouti',
  DK: 'Denmark',
  DM: 'Dominika',
  DO: 'Republik Dominika',
  DZ: 'Algeria',
  EA: 'Ceuta dan Melilla',
  EC: 'Ecuador',
  EE: 'Estonia',
  EG: 'Mesir',
  EH: 'Sahara Barat',
  ER: 'Eritrea',
  ES: 'Sepanyol',
  ET: 'Habsyah',
  EU: 'Kesatuan Eropah',
  FI: 'Finland',
  FJ: 'Fiji',
  FK: 'Kepulauan Falkland',
  FM: 'Micronesia',
  FO: 'Kepulauan Faroe',
  FR: 'Perancis',
  GA: 'Gabon',
  GB: 'United Kingdom',
  GD: 'Grenada',
  GE: 'Georgia',
  GF: 'Guiana Perancis',
  GG: 'Guernsey',
  GH: 'Ghana',
  GI: 'Gibraltar',
  GL: 'Greenland',
  GM: 'Gambia',
  GN: 'Guinea',
  GP: 'Guadeloupe',
  GQ: 'Guinea Khatulistiwa',
  GR: 'Yunani',
  GS: 'Georgia Selatan dan Kepulauan Sandwich Selatan',
  GT: 'Guatemala',
  GU: 'Guam',
  GW: 'Guinea-Bissau',
  GY: 'Guyana',
  HK: 'Hong Kong',
  HM: 'Pulau Heard dan Kepulauan McDonald',
  HN: 'Honduras',
  HR: 'Croatia',
  HT: 'Haiti',
  HU: 'Hungary',
  IC: 'Kepulauan Canaria',
  ID: 'Indonesia',
  IE: 'Ireland',
  IL: 'Israel',
  IM: 'Pulau Man',
  IN: 'India',
  IO: 'Wilayah Lautan Hindi British',
  IQ: 'Iraq',
  IR: 'Iran',
  IS: 'Iceland',
  IT: 'Itali',
  JE: 'Jersey',
  JM: 'Jamaika',
  JO: 'Jordan',
  JP: 'Jepun',
  KE: 'Kenya',
  KG: 'Kyrgyzstan',
  KH: 'Kemboja',
  KI: 'Kiribati',
  KM: 'Comoros',
  KN: 'Saint Kitts dan Nevis',
  KP: 'Korea Utara',
  KR: 'Korea Selatan',
  KW: 'Kuwait',
  KY: 'Kepulauan Cayman',
  KZ: 'Kazakhstan',
  LA: 'Laos',
  LB: 'Lebanon',
  LC: 'Saint Lucia',
  LI: 'Liechtenstein',
  LK: 'Sri Lanka',
  LR: 'Liberia',
  LS: 'Lesotho',
  LT: 'Lithuania',
  LU: 'Luxembourg',
  LV: 'Latvia',
  LY: 'Libya',
  MA: 'Maghribi',
  MC: 'Monaco',
  MD: 'Moldova',
  ME: 'Montenegro',
  MF: 'Saint Martin',
  MG: 'Madagaskar',
  MH: 'Kepulauan Marshall',
  MK: 'Macedonia',
  ML: 'Mali',
  MM: 'Myanmar [Burma]',
  MN: 'Mongolia',
  MO: 'Makau',
  MP: 'Kepulauan Mariana Utara',
  MQ: 'Martinique',
  MR: 'Mauritania',
  MS: 'Montserrat',
  MT: 'Malta',
  MU: 'Mauritius',
  MV: 'Maldives',
  MW: 'Malawi',
  MX: 'Mexico',
  MY: 'Malaysia',
  MZ: 'Mozambique',
  NA: 'Namibia',
  NC: 'New Caledonia',
  NE: 'Niger',
  NF: 'Pulau Norfolk',
  NG: 'Nigeria',
  NI: 'Nicaragua',
  NL: 'Belanda',
  NO: 'Norway',
  NP: 'Nepal',
  NR: 'Nauru',
  NU: 'Niue',
  NZ: 'New Zealand',
  OM: 'Oman',
  PA: 'Panama',
  PE: 'Peru',
  PF: 'Polinesia Perancis',
  PG: 'Papua New Guinea',
  PH: 'Filipina',
  PK: 'Pakistan',
  PL: 'Poland',
  PM: 'Saint Pierre dan Miquelon',
  PN: 'Kepulauan Pitcairn',
  PR: 'Puerto Rico',
  PS: 'Wilayah Palestin',
  PT: 'Portugal',
  PW: 'Palau',
  PY: 'Paraguay',
  QA: 'Qatar',
  QO: 'Kepulauan Terpencil Kecil Osenia',
  RE: 'Réunion',
  RO: 'Romania',
  RS: 'Serbia',
  RU: 'Rusia',
  RW: 'Rwanda',
  SA: 'Arab Saudi',
  SB: 'Kepulauan Solomon',
  SC: 'Seychelles',
  SD: 'Sudan',
  SE: 'Sweden',
  SG: 'Singapura',
  SH: 'Saint Helena',
  SI: 'Slovenia',
  SJ: 'Svalbard and Jan Mayen',
  SK: 'Slovakia',
  SL: 'Sierra Leone',
  SM: 'San Marino',
  SN: 'Senegal',
  SO: 'Somalia',
  SR: 'Suriname',
  SS: 'Sudan Selatan',
  ST: 'São Tomé dan Príncipe',
  SV: 'El Savador',
  SX: 'Sint Maarten',
  SY: 'Syria',
  SZ: 'Swaziland',
  TA: 'Tristan da Cunha',
  TC: 'Kepulauan Turks dan Caicos',
  TD: 'Chad',
  TF: 'Wilayah Selatan Perancis',
  TG: 'Togo',
  TH: 'Thailand',
  TJ: 'Tajikistan',
  TK: 'Tokelau',
  TL: 'Timor Timur',
  TM: 'Turkmenistan',
  TN: 'Tunisia',
  TO: 'Tonga',
  TR: 'Turki',
  TT: 'Trinidad dan Tobago',
  TV: 'Tuvalu',
  TW: 'Taiwan',
  TZ: 'Tanzania',
  UA: 'Ukraine',
  UG: 'Uganda',
  UM: 'Kepulauan Terpencil Kecil Amerika Syarikat',
  US: 'Amerika Syarikat',
  UY: 'Uruguay',
  UZ: 'Uzbekistan',
  VA: 'Kota Vatican',
  VC: 'Saint Vincent dan Kepulauan Grenadine',
  VE: 'Venezuela',
  VG: 'Kepulauan Virgin British',
  VI: 'U.S. Virgin Islands',
  VN: 'Vietnam',
  VU: 'Vanuatu',
  WF: 'Wallis dan Futuna',
  WS: 'Samoa',
  YE: 'Yaman',
  YT: 'Mayotte',
  ZA: 'Afrika Selatan',
  ZM: 'Zambia',
  ZW: 'Zimbabwe',
  ZZ: 'Wilayah Tidak Diketahu',
};
