export default {
  AC: 'Đảo Ascension',
  AD: 'Andorra',
  AE: 'Các tiểu vương quốc Ả Rập thống nhất',
  AF: 'Afghanistan',
  AG: 'Antigua và Barbuda',
  AI: 'Anguilla',
  AL: 'Albania',
  AM: 'Armenia',
  AN: 'Đảo Antilles của Hà Lan',
  AO: 'Angola',
  AQ: 'Châu Nam Cực',
  AR: 'Argentina',
  AS: 'Samoa thuộc Mỹ',
  AT: 'Áo',
  AU: 'Châu Úc',
  AW: 'Aruba',
  AX: 'Quần đảo Aland',
  AZ: 'Azerbaijan',
  BA: 'Bosnia và Herzegovina',
  BB: 'Barbados',
  BD: 'Bangladesh',
  BE: 'Nước Bỉ',
  BF: 'Burkina Faso',
  BG: 'Bungari',
  BH: 'Bahrain',
  BI: 'Burundi',
  BJ: 'Bénin',
  BL: 'Saint Barthélemy',
  BM: 'Bermuda',
  BN: 'Brunei',
  BO: 'Bolivia',
  BQ: 'Caribbean Hà Lan',
  BR: 'Braxin',
  BS: 'Bahamas',
  BT: 'Bhutan',
  BV: 'Đảo Bouvet',
  BW: 'Botswana',
  BY: 'Belarus',
  BZ: 'Belize',
  CA: 'Canada',
  CC: 'Quần đảo Cocos [Keeling]',
  CD: 'Kinshasa-Cộng hòa dân chủ Congo',
  CF: 'Cộng hòa Trung Phi',
  CG: 'Cộng hòa Congo',
  CH: 'Thụy sĩ',
  CI: 'Côte d’Ivoire',
  CK: 'Quần đảo Cook',
  CL: 'Chile',
  CM: 'Cameroon',
  CN: 'Trung Quốc',
  CO: 'Colombia',
  CP: 'Đảo Clipperton',
  CR: 'Costa Rica',
  CU: 'Cuba',
  CV: 'Cabo Verde',
  CW: 'Curaçao',
  CX: 'Đảo Giáng Sinh',
  CY: 'Cộng hòa Síp',
  CZ: 'Cộng hòa Séc',
  DE: 'Nước Đức',
  DG: 'Diego Garcia',
  DJ: 'Djibouti',
  DK: 'Đan mạch',
  DM: 'Dominica',
  DO: 'Cộng hòa Dominica',
  DZ: 'Algérie',
  EA: 'Ceuta và Melilla',
  EC: 'Ecuador',
  EE: 'Estonia',
  EG: 'Ai Cập',
  EH: 'Phía tây Sahara',
  ER: 'Eritrea',
  ES: 'Tây Ban Nha',
  ET: 'Ethiopia',
  EU: 'Liên minh châu Âu',
  FI: 'Phần Lan',
  FJ: 'Fiji',
  FK: 'Quần đảo Falkland',
  FM: 'Liên bang Micronesia',
  FO: 'Quần đảo Faroe',
  FR: 'Nước Pháp',
  GA: 'Gabon',
  GB: 'Vương quốc Anh',
  GD: 'Grenada',
  GE: 'Georgia',
  GF: 'Guiana thuộc Pháp',
  GG: 'Guernsey',
  GH: 'Ghana',
  GI: 'Gibraltar',
  GL: 'Greenland',
  GM: 'Gambia',
  GN: 'Guinea',
  GP: 'Guadeloupe',
  GQ: 'Guinea Xích Đạo',
  GR: 'Hy Lạp',
  GS: 'Nam Georgia và Quần đảo Nam Sandwich',
  GT: 'Guatemala',
  GU: 'Guam',
  GW: 'Guinea-Bissau',
  GY: 'Guyana',
  HK: 'Hồng Kông',
  HM: 'Đảo Heard và quần đảo McDonald',
  HN: 'Honduras',
  HR: 'Croatia',
  HT: 'Haiti',
  HU: 'Hungary',
  IC: 'đảo Canary',
  ID: 'Indonesia',
  IE: 'Ireland',
  IL: 'Israel',
  IM: 'Đảo Man',
  IN: 'Ấn Độ',
  IO: 'Lãnh thổ Ấn Độ Dương thuộc Anh',
  IQ: 'Iraq',
  IR: 'Iran',
  IS: 'Iceland',
  IT: 'Nước ý',
  JE: 'Jersey',
  JM: 'Jamaica',
  JO: 'Jordan',
  JP: 'Nhật Bản',
  KE: 'Kenya',
  KG: 'Kyrgyzstan',
  KH: 'Campuchia',
  KI: 'Kiribati',
  KM: 'Comoros',
  KN: 'Saint Kitts và Nevis',
  KP: 'Bắc Triều Tiên',
  KR: 'Hàn Quốc',
  KW: 'Kuwait',
  KY: 'Quần đảo Cayman',
  KZ: 'Kazakhstan',
  LA: 'Lào',
  LB: 'Lebanon',
  LC: 'Saint Lucia',
  LI: 'Liechtenstein',
  LK: 'Sri Lanka',
  LR: 'Liberia',
  LS: 'Lesotho',
  LT: 'Lithuania',
  LU: 'Luxembourg',
  LV: 'Latvia',
  LY: 'Libya',
  MA: 'Morocco',
  MC: 'Monaco',
  MD: 'Moldova',
  ME: 'Montenegro',
  MF: 'Saint martin',
  MG: 'Madagascar',
  MH: 'Đảo Marshall',
  MK: 'Macedonia',
  ML: 'Mali',
  MM: 'Myanmar (Miến Điện]',
  MN: 'Mông Cổ',
  MO: 'Ma Cao',
  MP: 'Quần đảo Bắc Mariana',
  MQ: 'Martinique',
  MR: 'Mauritania',
  MS: 'Montserrat',
  MT: 'Malta',
  MU: 'Mauritius',
  MV: 'Maldives',
  MW: 'Malawi',
  MX: 'Mexico',
  MY: 'Malaysia',
  MZ: 'Mozambique',
  NA: 'Namibia',
  NC: 'New Caledonia',
  NE: 'Niger',
  NF: 'Đảo Norfolk',
  NG: 'Nigeria',
  NI: 'Nicaragua',
  NL: 'Nước Hà Lan',
  NO: 'Na Uy',
  NP: 'Nepal',
  NR: 'Nauru',
  NU: 'Niue',
  NZ: 'New Zealand',
  OM: 'Oman',
  PA: 'Panama',
  PE: 'Peru',
  PF: 'Polynésie thuộc Pháp',
  PG: 'Papua New Guinea',
  PH: 'Philippines',
  PK: 'Pakistan',
  PL: 'Ba Lan',
  PM: 'Saint Pierre và Miquelon',
  PN: 'Quần đảo Pitcairn',
  PR: 'Puerto Rico',
  PS: 'Lãnh thổ Palestine',
  PT: 'Bồ Đào Nha',
  PW: 'Palau',
  PY: 'Paraguay',
  QA: 'Qatar',
  QO: 'Châu Đại Dương xa xôi',
  RE: 'Sum họp',
  RO: 'Rumani',
  RS: 'Serbia',
  RU: 'Nước Nga',
  RW: 'Rwanda',
  SA: 'Ả Rập Xê Út',
  SB: 'Quần đảo Solomon',
  SC: 'Seychelles',
  SD: 'Sudan',
  SE: 'Thụy Điển',
  SG: 'Singapore',
  SH: 'Saint Helena',
  SI: 'Slovenia',
  SJ: 'Svalbard và Jan Mayen',
  SK: 'Slovakia',
  SL: 'Sierra Leone',
  SM: 'San Marino',
  SN: 'Senegal',
  SO: 'Somalia',
  SR: 'Suriname',
  SS: 'Phía nam Sudan',
  ST: 'São Tomé và Príncipe',
  SV: 'El Salvador',
  SX: 'Sint Maarten',
  SY: 'Syria',
  SZ: 'Swaziland',
  TA: 'Tristan da Cunha',
  TC: 'Quần đảo Turks và Caicos',
  TD: 'Chad',
  TF: 'Lãnh thổ phía Nam của Pháp',
  TG: 'Togo',
  TH: 'Thái Lan',
  TJ: 'Tajikistan',
  TK: 'Tokelau',
  TL: 'Đông Timor',
  TM: 'Turkmenistan',
  TN: 'Tunisia',
  TO: 'Tonga',
  TR: 'Thổ Nhĩ Kỳ',
  TT: 'Trinidad và Tobago',
  TV: 'Tuvalu',
  TW: 'Đài Loan',
  TZ: 'Tanzania',
  UA: 'Ukraine',
  UG: 'Uganda',
  UM: 'Các tiểu đảo xa của Hoa Kỳ',
  US: 'Hoa Kỳ',
  UY: 'Uruguay',
  UZ: 'Uzbekistan',
  VA: 'Thành phố Vatican',
  VC: 'Saint Vincent và Grenadines',
  VE: 'Venezuela',
  VG: 'Quần đảo Virgin thuộc Anh',
  VI: 'Quần đảo Virgin thuộc Mỹ',
  VN: 'Việt Nam',
  VU: 'Vanuatu',
  WF: 'Wallis và Futuna',
  WS: 'Samoa',
  YE: 'Yemen',
  YT: 'Mayotte',
  ZA: 'Nam Phi',
  ZM: 'Zambia',
  ZW: 'Zimbabwe',
  ZZ: 'Vùng không xác định',
};
