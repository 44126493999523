import country from './country/th';

export default {
  translation: {
    ...country,
    'Express checkout': 'Express checkout',
    'Or fill in the information': 'หรือกรอกข้อมูลด้านล่าง',
    'Form Validation - required': '{{fieldName}} ให้ครบถ้วน',
    'Form Validation - max': '{{fieldName}} ยาวเกินไป',
    'Form Validation - maxLength': '{{fieldName}} ยาวเกินไป',
    'Form Validation - min': '{{fieldName}} สั้นเกินไป',
    'Form Validation - pattern': '{{fieldName}} ไม่ถูกต้อง',
    'Form Validation - validate': '{{fieldName}} ไม่ถูกต้อง',
    'Form Validation - validate - minAge':
      'Birthday is invalid, should over {{minAge}} years old.',
    'Form Validation - phone - validate - pattern':
      '{{fieldName}} ไม่ถูกต้อง กรุณาตรวจสอบอีกครั้ง',
    'Form Validation - terms':
      'คุณต้องยอมรับต่อข้อกำหนดและเงื่อนไขเพื่อดำเนินการต่อ',
    'Form Action Deleting': 'deleting',
    IAgreeTerms:
      'ฉันยอมรับ <0>เงื่อนไขบริการ</0> และ <2>นโยบายความเป็นส่วนตัว</2> ของร้านค้า',
    'Terms of Use and Privacy Policy and Age Policy':
      "I agree to the store's <0>Term of Use</0> and <2>Privacy Policy</2> and I am over {{ age }}",
    Terms: '<0>เงื่อนไขบริการ</0> และ <2>นโยบายความเป็นส่วนตัว</2>',
    'Ask Signin': 'ลงทะเบียนเรียบร้อยแล้ว?',
    'Member Login': 'เข้าสู่ระบบ',
    Login: 'เข้าสู่ระบบ',
    'Customer Information': 'ข้อมูลของลูกค้า',
    'Full Name': 'ชื่อ',
    'Email Address': 'อีเมล',
    'Create an account with shop': 'สร้างบัญชีกับร้าน {{shopName}}',
    'Send email updates and promotions':
      'I accept to receive shop updates, promotions and other marketing information from {{merchantName}}',
    'Shipping Address': 'รายละเอียดการจัดส่งสินค้า',
    'Recipient Name': 'ชื่อผู้รับ',
    'Recipient Contact Number': 'หมายเลขโทรศัพท์ของผู้รับ',
    'Address Line 1': 'Address',
    'Address Line 1 DE': 'Street and house number',
    'Address Line 2': 'Apartment, suite, etc. (ไม่จำเป็น)',
    'Address Line 2 DE': 'Additional address (ไม่จำเป็น)',
    Address: 'Address',
    'Shipping Destination': 'Shipping Destination',
    'US Shipping Destination': 'Country/Region',
    City: 'City',
    State: 'State/Province/Region',
    'US State': 'State',
    Zipcode: 'ZIP / Postal Code',
    'Zipcode GB': 'Postcode',
    'Zipcode FR': 'Postal Code',
    'Zipcode DE': 'Postal code',
    'Payment Detail': 'รายละเอียดการชำระเงิน',
    'Payment Fee': 'ค่าธรรมเนียมการชำระเงิน',
    'Total Order Over Hint':
      'คำสั่งซื้อนี้จะต้องถึงยอดสั่งซื้อขั้นต่ำ {{amount}} เพื่อดำเนินการเช็คเอาท์',
    'Delivery Method': 'วิธีการจัดส่งสินค้า',
    'Delivery Fee': 'ค่าจัดส่งสินค้า',
    'Remarks for Shop': 'ข้อมูลเพิ่มเติม',
    'Remarks for Shop Placeholder': 'ระบุหมายเหตุคำสั่งซื้อถึงร้านค้าได้ที่นี่',
    'Cart Title': 'รายการสินค้า',
    'Cart Total': 'ยอดรวมทั้งสิ้น',
    'Cart Subtotals subtotal': 'ยอดรวมรายการ',
    'Cart Subtotals discount': 'ส่วนลดถูกใช้แล้ว',
    'Cart Subtotals total_tax_fee': 'ค่าธรรมเนียมภาษ',
    'Cart Subtotals applied_user_credits': 'ใช้งานเครดิตร้านค้าเรียบร้อย',
    'Cart Subtotals delivery_fee': 'ค่าจัดส่งสินค้า',
    'Cart Subtotals payment_fee': 'ค่าธรรมเนียมการชำระเงิน',
    'Cart Subtotals custom_discount': 'ส่วนลด (กำหนดเอง)',
    'Cart Subtotals applied_member_point_redeem_to_cash':
      'คะแนนส่วนลดที่ถูกใช้',
    'Cart delivery_fee Free': 'ฟรี',
    'Cart delivery_fee Partial Free': 'จัดส่งฟรีบางส่วน',
    'Cart Coupon code apply success': 'Coupon Applied',
    'Cart Affiliate code apply success': 'Affiliate Applied',
    'Place Order': 'ยืนยันคำสั่งซื้อ',
    'Promotion Label - Promotion': 'โปรโมชั่นท',
    'Promotion Label - Subscription Promotion':
      'โปรโมชันสำหรับการสมัครสมาชิกซื้อสินค้าแบบประจำ',
    'Promotion Label - Credit Reward': 'Reward Credits',
    'Promotion Label - Bundle Pricing': 'แพ็ครวม',
    'Promotion Label - Bundle Group': 'กลุ่มแพ็ครวม',
    'Promotion Label - Buy X Get Y': 'Buy X Get Y',
    'Promotion Label - Member Point Redeem Gift': 'แคมเปญคะแนน',
    'Promotion Label - Member Tier Promotion': 'ข้อเสนอสำหรับสมาชิก',
    'Custom Discount Item Label': 'ส่วนลดกำหนดเอง',
    'Max Discount Price custom_discount': 'สูงสุดถึง',
    'Apply Code': 'ตรวจสอบ',
    'Enter Coupon Code': 'ใส่โค้ดคูปอง',
    'Enter Affiliate Code': 'ใช้รหัสแอฟฟิลิเอท',
    'Free Checkout': 'ชำระเงินฟรี',
    'Form Error - Not Found Address':
      'Sorry, the address you have filled in is out of the delivery scope.',
    'Form Error - Not Found Delivery':
      'Sorry, the address you have filled in is out of the delivery scope.  Please choose another address.',
    'Form Error - Card Type Not Support':
      'The card type is not supported at this moment',
    'Credit Card Holder Name': 'ชื่อผู้ถือบัตร',
    'Credit Card Number': 'หมายเลขบัตรเครดิต',
    'Credit Card Expiry Date': 'วันหมดอายุ (MM/YY)',
    'Credit Card CVC': 'CVC',
    'SlPayment Credit Card Holder Name Hint': 'เหมือนกับชื่อและนามสกุลบนบัตร',
    'SlPayment Credit Card Expiry Date': 'วันหมดอายุ',
    'SlPayment Credit Card Expiry Date Month Placeholder': 'MM',
    'SlPayment Credit Card Expiry Date Year Placeholder': 'YY',
    'Payment General Error':
      'ขออภัยมีปัญหากับการสั่งซื้อโปรดตรวจสอบข้อมูลและลองอีกครั้งหรือติดต่อร้านค้า ขอบคุณ!',
    'Payment 3D Verification Failed':
      'การตรวจสอบความปลอดภัยแบบ 3 มิติล้มเหลวโปรดลองอีกครั้งหรือเลือกวิธีการชำระเงินอื่น ขอบคุณ!',
    'CVC Hint': 'เลข 3 ตัวสุดท้ายหลังบัตรเครดิตของคุณเพื่อความปลอดภัย',
    'Product Quantity Validation - Sold Out':
      'สินค้าหมด <1>กรุณาลบสินค้าออก</1>',
    'Product Quantity Validation - Invalid Format': 'จำเป็นต้อง ≥ 1',
    'Product Quantity Validation - Low Inventory': 'เหลือ {{ value }} เท่านั้น',
    'Product Quantity Validation - Purchase Limit':
      'จำกัด {{ value }} ต่อคำสั่งซื้อ',
    'Product Quantity Validation - Quantity Over Inventory':
      'สินค้าคงคลังไม่เพียงพอ',
    'Product Quantity Validation - Customer Purchase Limit Without Record':
      'จำกัด {{ limit }} ชิ้นต่อ 1 ท่าน',
    'Product Quantity Validation - Customer Purchase Limit With Record':
      'จำกัด {{ limit }} ชิ้นต่อ 1 ท่าน คุณสามารถซื้อเพิ่มได้อีก {{ left }} ชิ้นเท่านั้น',
    'Product Quantity Validation - Customer Purchase Limit Exceeded':
      'จำกัด {{ limit }} ชิ้นต่อ 1 ท่าน คุณได้ซื้อครบจำนวนชิ้นที่กำหนดแล้ว',
    'Product Quantity Validation - Invalid':
      'ไม่ถูกต้อง <1>กรุณาลบสินค้าออก</1>',
    'Remove Product Popup - title': 'ลบสินค้าออก',
    'Remove Product Popup - content':
      'คุณแน่ใจหรือว่าจะลบ {{ itemName }} ออกจากตะกร้าสินค้าของคุณ',
    'Remove Product Popup - cancel': 'ยกเลิก',
    'Remove Product Popup - remove': 'ลบ',
    'Add-On Item Validation - Purchase Limit':
      'สินค้า add-on ไม่ควรมีจำนวนมากกว่าสินค้าหลัก',
    'Product(s) ordered from livestream are reserved':
      'การสั่งจองสินค้าที่สั่งจากไลฟ์สตรีมเรียบร้อยแล้วจนถึงวันที่ ,<0>{{ countdown }}</0>รีบรับของของคุณกลับบ้านได้เลย !',
    'Live Stream limited time reservation':
      'การจองไลฟ์สตรีมในเวลาจำกัด {{ qty }} ชิ้น',
    'Live Stream limited time reservation (separator)': ', ',
    'Live Stream limited time reservation (unit)': '{{qty}} ชิ้น',
    'Tooltip date format': 'YYYY/MM/DD HH:mm',
    'Reserve till': `จองได้ถึง {{ endDateFormat }} น.
      (ไลฟ์สตรีมเมื่อ {{ startDateFormat }} น.:
      {{ title }})
      แสดงตามเขตเวลาท้องถิ่นของคุณ (GMT{{ offset }})`,
    'Inclusive Tax Hint': '{{taxName}} {{taxRate}}% รวมภาษี: {{taxAmount}}',
    'Field (optional)': '{{fieldName}} (ไม่จำเป็น)',
    'Field - gender': 'เพศ',
    'Field - birthday': 'วันเกิด',
    'Gender - male': 'ชาย',
    'Gender - female': 'หญิง',
    'Gender - other': 'ไม่เปิดเผย',
    'Saved Addresses - new': 'ที่อยู่ใหม่',
    'Saved Addresses - default': 'ที่อยู่เริ่มต้น',
    'Saved Addresses - saved address': 'ที่อยู่ที่บันทึกไว้',
    'Saved Addresses - save address':
      'บันทึกที่อยู่ใหม่นี้ลงในสมุดที่อยู่ของฉัน',
    'Add To Cart': 'หยิบใส่รถเข็น',
    'Add To Cart Success': 'เพิ่มสินค้าลงในตะกร้าสินค้าของคุณแล้ว',
    'Addon Reminder': 'อย่าพลาดสินค้า add-on ของเราด้านล่างนี้!',
    'Addon Limit': 'LIMIT {{limit}} PER ORDER',
    'Reach Purchase Limit': 'ถึงขีดจำกัดการซื้อ',
    'Sold Out': 'สินค้าหมด',
    'Addon Min Price':
      'ได้รับเพิ่มสินค้าเพิ่มเติมเมื่อซื้อสินค้ามากกว่า {{minPrice}}',
    'Addon Item': 'รายการเสริม',
    'Free Gift': 'ของขวัญฟรี',
    'Cart Over Limit Error':
      'จำนวนสินค้าสูงสุดคือ 100 ชิ้น กรุณาปรับจำนวนและดำเนินการซื้อสินค้าอีกครั้ง',
    Cancel: 'ยกเลิก',
    Continue: 'ดำเนินการต่อ',
    'Auto Fill - Title': 'ป้อนโทรศัพท์ของคุณเพื่อชำระเงินด่วน',
    'Auto Fill - Phone Error': 'กรุณากรอกรูปแบบที่ถูกต้อง',
    'Auto Fill - Toast':
      'หากไม่มีบันทึกการสั่งซื้อล่าสุด ระบบจะไม่นำเข้าข้อมูลการชำระเงินด่วน',
    QuickRegistrations_creditValueDescription:
      'กรุณากรอกข้อมูลสมาชิก คุณจะได้รับเครดิตร้านค้า ${{ creditValue }} เครดิต หลังจากกรอกข้อมูลเสร็จสิ้น',
    QuickRegistrations_isVerified: 'ยืนยันแล้ว',
    QuickRegistrations_isUnverified: 'ยังไม่ยืนยัน',
    QuickRegistrations_Input_Phone_Invalid_Format:
      'โปรดป้อนรูปแบบหมายเลขโทรศัพท์ที่ถูกต้อง',
    QuickRegistrations_Input_Password_Required: 'Login password is required',
    QuickRegistrations_Input_Password_Too_Short: 'Password is too short',
    QuickRegistrations_Input_Password_Too_Long: 'Password is too long',
    QuickRegistrations_Input_Password_Complexity:
      'Password At least 8 characters with no space\nPassword At least 1 upper case letter\nPassword At least 1 lower case letter\nPassword At least 1 number\nPassword At least 1 of the following special characters from(e.g  ! # $ ^ * )',
    QuickRegistrations_Input_Email_Invalid_Format: 'กรุณากรอกอีเมลที่ถูกต้อง',
    QuickRegistrations_Input_Phone_Placeholder: 'กรุณากรอกหมายเลขโทรศัพท์',
    QuickRegistrations_Input_Email_Placeholder: 'กรุณากรอกอีเมล',
    QuickRegistrations_Input_Password_Placeholder: 'Login password',
    QuickRegistrations_Input_Name_Placeholder: 'โปรดกรอกชื่อเต็ม',
    QuickRegistrations_Input_Phone_Label: 'หมายเลขโทรศัพท์',
    QuickRegistrations_Input_Email_Label: 'อีเมล',
    QuickRegistrations_Input_Password_Label: 'Password',
    QuickRegistrations_Input_Name_Label: 'ชื่อสมาชิก',
    QuickRegistrations_Input_Birthday: 'วันเกิด',
    QuickRegistrations_Input_Birthday_Year_Placeholder: 'ปี',
    QuickRegistrations_Input_Birthday_Month_Placeholder: 'เดือน',
    QuickRegistrations_Input_Birthday_Day_Placeholder: 'วัน',
    QuickRegistrations_Input_Gender_Female: 'หญิง',
    QuickRegistrations_Input_Gender_Male: 'ชาย',
    QuickRegistrations_Input_Gender_Other: 'ไม่เปิดเผย',
    QuickRegistrations_Required_Input_Placeholder: '{{ field }}(required)',
    QuickRegistrations_Submit: 'ส่ง',
    QuickRegistrations_Modal_getCreditSuccess:
      'ได้รับเครดิตร้านค้าเรียบร้อยแล้ว',
    QuickRegistrations_Modal_getCreditAlready: 'ได้รับเครดิตร้านค้าแล้ว',
    QuickRegistrations_Modal_BindingLINEFailed_title: 'ไม่สามารถเชื่อมต่อได้',
    QuickRegistrations_Modal_Social_User_Binding_Error:
      'การสมัครล้มเหลว กรุณาติดต่อร้านค้า',
    QuickRegistrations_Modal_Social_User_Double_Binding_Error:
      'สมัครล้มเหลว บัญชีโซเชียลนี้ถูกผูกกับสมาชิกท่านอื่นแล้ว กรุณาติดต่อร้านค้าเพื่อยกเลิกการผูก',
    QuickRegistrations_Modal_Platform_Id_Different_Error: '',
    QuickRegistrations_Modal_Platform_User_Id_Signed_Up_By_Others_Error:
      'สมัครล้มเหลว บัญชี LINE นี้ถูกใช้สมัครแล้วโดยสมาชิกท่านอื่น กรุณาลงชื่อเข้าสู่ระบบด้วยบัญชี LINE และลองอีกครั้ง',
    QuickRegistrations_Modal_Line_Error:
      'การสมัครล้มเหลว กรุณาเข้าสู่ระบบ LINE อีกครั้ง',
    QuickRegistrations_Modal_BindingLINEFailed_confirm: 'ยืนยัน',
    QuickRegistrations_already_used: 'ถูกลงทะเบียนโดยสมาชิกอื่น',
    QuickRegistrations_custom_field_is_required:
      'จำเป็นต้องกรอก {{ fieldName }}',
    QuickRegistrations_minimum_age_limit:
      'วันเกิดไม่ถูกต้อง ควรมีอายุมากกว่า {{ age }} ปี',
    QuickRegistrations_Member_Info_Hint:
      'กรอกข้อมูลสมาชิกดังต่อไปนี้: {{ fields }} คุณจะได้รับ {{ rewards }} หลังจากกรอกข้อมูลสมาชิกเรียบร้อยแล้ว',
    QuickRegistrations_Member_Info_Sent:
      'หลังจากการตรวจสอบข้อมูลเสร็จสิ้น โบนัสการกรอกประวัติสมาชิกจะออกให้!',
    QuickRegistrations_Member_Info_Symbol: ', ',
    QuickRegistrations_Member_Info_Name: 'ช่ือ',
    QuickRegistrations_Member_Info_Email: 'อีเมล',
    QuickRegistrations_Member_Info_Mobile: 'หมายเลขโทรศัพท์',
    QuickRegistrations_Member_Info_Gender: 'เพศ',
    QuickRegistrations_Member_Info_Birthday: 'วันเกิด',
    QuickRegistrations_Member_Info_User_Credits:
      'เครดิตร้านค้า {{ count }} คะแนน',
    QuickRegistrations_Member_Info_Member_Points:
      'คะแนนสมาชิก {{ count }} คะแนน',
    QuickRegistrations_Member_Info_Coupons: 'คูปอง {{ count }} ใบ',
  },
  widget: {
    Subscribed: 'สมัครสมาชิกแล้ว',
    'Invalid email': 'อีเมลไม่ถูกต้อง',
    'Please try again': 'กรุณาลองใหม่อีกครั้ง',
    'You have requested too many times, please try again later':
      'คุณขอหลายครั้งเกินไป โปรดลองอีกครั้งในภายหลัง',
    'Clear All Filter': 'ลบทั้งหมด',
  },
};
