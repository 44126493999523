import i18next from 'i18next';
import en from '../locale/en';
import vi from '../locale/vi';
import th from '../locale/th';
import ms from '../locale/ms';
import fr from '../locale/fr';
import de from '../locale/de';
import id from '../locale/id';
import ja from '../locale/ja';
import zhHant from '../locale/zh-hant';
import zhCn from '../locale/zh-cn';

const newInstance = i18next.createInstance(
  {
    debug: process.env.NODE_ENV === 'development',
    ns: ['widget'],
    defaultNS: 'widget',
    fallbackLng: 'en',
    lowerCaseLng: true,
    resources: {
      en,
      vi,
      th,
      ms,
      fr,
      de,
      id,
      ja,
      'zh-hant': zhHant,
      'zh-cn': zhCn,
    },
  },
  () => {},
);

window.addEventListener('load', () => {
  newInstance.changeLanguage(window.mainConfig.localeData.loadedLanguage.code);
});

export default newInstance;
