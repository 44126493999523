import country from './country/ja';

export default {
  translation: {
    ...country,
    'Express checkout': 'Express checkout',
    'Or fill in the information': 'Or fill in the information',
    'Form Validation - required': '{{fieldName}} 必要とされている',
    'Form Validation - max': '{{fieldName}} is too long',
    'Form Validation - maxLength': '{{fieldName}} is too long',
    'Form Validation - min': '{{fieldName}} is too short',
    'Form Validation - pattern': '{{fieldName}} is invalid',
    'Form Validation - validate': '{{fieldName}} is invalid',
    'Form Validation - validate - minAge':
      'Birthday is invalid, should over {{minAge}} years old.',
    'Form Validation - terms':
      'ショップの利用規約とプライバシーポリシーに同意する必要があります',
    'Form Action Deleting': 'deleting',
    IAgreeTerms:
      '私は<0>店舗の利用規約</0>および<2>プライバシーポリシー</2>に同意します',
    Terms: '<0>店舗の利用規約</0>および<2>プライバシーポリシー</2>',
    'Ask Signin': 'すでに登録？',
    'Member Login': 'ログイン',
    Login: 'ログイン',
    'Customer Information': 'お客様の情報',
    'Full Name': 'フルネーム',
    'Email Address': 'メールアドレス',
    'Email Address Optional': 'メールアドレス（任意）',
    'Create an account with shop': '{{shopName}} のアカウントを作成',
    'Send email updates and promotions':
      '{{merchantName}} からショップの最新情報、プロモーション、その他のマーケティング情報を受け取ることに同意します',
    'Shipping Address': 'お届け先住所',
    'Recipient Name': 'お届け先のお名前',
    'Recipient Contact Number': '電話番号',
    'Customer Contact Number': 'Customer contact number',
    'Address Line 1': 'Address',
    'Address Line 1 DE': 'Street and house number',
    'Address Line 2': 'Apartment, suite, etc. (optional)',
    'Address Line 2 DE': 'Additional address (optional)',
    Address: 'Address',
    'Shipping Destination': 'Shipping Destination',
    'US Shipping Destination': 'Country/Region',
    City: 'City',
    State: 'State/Province/Region',
    'US State': 'State',
    Zipcode: 'ZIP / Postal Code',
    'Zipcode GB': 'Postcode',
    'Zipcode FR': 'Postal Code',
    'Zipcode DE': 'Postal code',
    'Payment Detail': 'お支払いの詳細',
    'Payment Fee': '支払い手数料',
    'Total Order Over Hint':
      'This order must meet the minimum amount {{amount}} in order to proceed to checkout.',
    'Delivery Method': '配送方法',
    'Delivery Fee': '配送料',
    'Remarks for Shop': 'その他の情報',
    'Remarks for Shop Placeholder':
      'ご注文に関して備考がありましたら記入してください（任意）',
    'Cart Title': '商品リスト',
    'Cart Total': '合計',
    'Cart Subtotals subtotal': '小計',
    'Cart Subtotals discount': '割引の適用',
    'Cart Subtotals total_tax_fee': '税額',
    'Cart Subtotals applied_user_credits': 'Applied Store Credit',
    'Cart Subtotals delivery_fee': 'Delivery Fee',
    'Cart Subtotals payment_fee': '支払い手数料',
    'Cart Subtotals custom_discount': '割引き（カスタム）',
    'Cart delivery_fee Free': 'Free',
    'Cart delivery_fee Partial Free': 'Partial Free Shipping',
    'Place Order': '注文を確定する',
    'Promotion Label - Promotion': 'プロモーション',
    'Promotion Label - Subscription Promotion':
      'Product Subscription Promotion',
    'Promotion Label - Credit Reward': 'Reward Credits',
    'Promotion Label - Bundle Pricing': 'バンドル',
    'Promotion Label - Bundle Group': 'バンドルグループ',
    'Promotion Label - Buy X Get Y': 'Buy X Get Y',
    'Promotion Label - Member Point Redeem Gift': 'ポイントキャンペーン',
    'Promotion Label - Member Tier Promotion': '会員ランク割引',
    'Custom Discount Item Label': '割引き',
    'Max Discount Price custom_discount': '最大割引',
    'Apply Code': 'Validate',
    'Enter Coupon Code': 'クーポンコードを入力',
    'Enter Affiliate Code': 'アフィリエイトコードを適用',
    'Free Checkout': 'Free Checkout',
    'Form Error - Not Found Address':
      'Sorry, the address you have filled in is out of the delivery scope.',
    'Form Error - Not Found Delivery':
      'Sorry, the address you have filled in is out of the delivery scope.  Please choose another address.',
    'Form Error - Card Type Not Support':
      'The card type is not supported at this moment',
    'Credit Card Holder Name': 'Cardholder Name',
    'Credit Card Number': 'Credit Card Number',
    'Credit Card Expiry Date': 'Expiry Date (MM/YY)',
    'Credit Card CVC': 'CVC',
    'SlPayment Credit Card Holder Name Hint': 'Same as the full name on card',
    'SlPayment Credit Card Expiry Date': 'Expiry Date',
    'SlPayment Credit Card Expiry Date Month Placeholder': 'MM',
    'SlPayment Credit Card Expiry Date Year Placeholder': 'YY',
    'Payment General Error':
      'Error processing order, please verify your information and try again. If the problem persists, please email shop owner for details.',
    'Payment 3D Verification Failed':
      '3D secure verification failed, please try again or select other payment methods, thank you!',
    'CVC Hint': '3-digit security code usually found on the back of your card.',
    'Product Quantity Validation - Sold Out':
      'Sold out, please <1>remove it</1>',
    'Product Quantity Validation - Invalid Format': 'Must ≥ 1',
    'Product Quantity Validation - Low Inventory': 'Left {{value}} only',
    'Product Quantity Validation - Purchase Limit': 'Limit {{value}} per order',
    'Product Quantity Validation - Quantity Over Inventory': 'Not enough stock',
    'Points used to redeem gifts': 'ギフト交換に使用されたポイント',
    'Points earned after days':
      '獲得ポイント {{number}} 日間が受取日より経過してから',
    'Points earned after order completed': 'Points earned after completed',
    'Credits earned after order completed': 'Credits earned after completed',
    Points: 'ポイント',
    Credits: 'credit(s)',
    'Free Gift': '無料ギフト',
    'Remove Product Popup - title': 'Remove product',
    'Remove Product Popup - content':
      'Are you sure to remove {{itemName}} from your shopping cart?',
    'Remove Product Popup - cancel': 'Cancel',
    'Remove Product Popup - remove': 'Remove',
    'Add-On Item Validation - Purchase Limit':
      'The quantity of add-on item should not over its main product',
    'Product(s) ordered from livestream are reserved':
      'Product(s) ordered from livestream are reserved,<0>{{ countdown }}</0>left to bring it home!',
    'Live Stream limited time reservation':
      'Live Stream limited time reservation {{ qty }} piece(s)',
    'Live Stream limited time reservation (separator)': ', ',
    'Live Stream limited time reservation (unit)': '{{qty}} piece(s)',
    'Tooltip date format': 'YYYY/MM/DD HH:mm',
    'Reserve till': `Reserve till {{ endDateFormat }}
      (Live streamed at {{ startDateFormat }}:
      {{ title }})
      Display according to your local time zone (GMT{{ offset }})`,
    'Inclusive Tax Hint':
      '{{taxName}} {{taxRate}}% Tax Included: {{taxAmount}}',
    'SL FPX Payment Hint':
      'You are using SHOPLINE Payments to checkout now. By completing the payment, you agree to the <a href="/about/terms" target="_blank">Terms of Service</a> of SHOPLINE Payments and <a href="https://www.mepsfpx.com.my/FPXMain/termsAndConditions.jsp" target="_blank">Terms of Services</a> of FPX',
    'SL FPX Checkout Term':
      "You will be redirected to the bank's website to finish the payment. Please remember to click the confirm payment button to return to the order page to make sure the order is placed.",
    'Select Bank': 'Select Bank',
    'Field (optional)': '{{fieldName}} (optional)',
    'Field - gender': 'Gender',
    'Field - birthday': 'Birthday',
    'Gender - male': 'Male',
    'Gender - female': 'Female',
    'Gender - other': 'Other',
    'Birthday - hint':
      'オンラインストアで登録して買い物をするには、一定の年齢以上である必要があります。',
    'Saved Addresses - new': '新しい住所',
    'Saved Addresses - default': '既定の住所',
    'Saved Addresses - saved address': '保存済みの先住所',
    'Saved Addresses - save address': 'この新しい住所をアドレス帳に保存する',
    'Add To Cart': 'ADD TO CART',
    'Add To Cart Success': 'カートにアイテムを追加しました',
    'Addon Reminder': "Don't miss out on our add-on products below!",
    'Addon Limit': 'LIMIT {{limit}} PER ORDER',
    'Reach Purchase Limit': '上限に達しました',
    'Sold Out': '売り切れ',
    'Addon Min Price': 'Get Add-on Items with purchase over {{minPrice}}',
    'Addon Item': 'Item Add-on',
    'Cart Over Limit Error':
      'The maximum number of items is 100, please adjust the quantity and purchase again',
    Cancel: 'Cancel',
    Continue: 'Continue',
    'Auto Fill - Title': 'Enter your phone for fast checkout',
    'Auto Fill - Phone Error': 'Please enter the correct format',
    'Auto Fill - Toast':
      'If there is no record of the last order detected, the fast checkout data will not be brought in',
  },
  widget: {
    'Clear All Filter': 'すべてクリア',
  },
};
