import country from './country/my';

export default {
  translation: {
    ...country,
    'Express checkout': 'Express checkout',
    'Or fill in the information': 'Or fill in the information',
    'Form Validation - required': 'Alamat {{fieldName}} diperlukan',
    'Form Validation - max': '{{fieldName}} is too long',
    'Form Validation - maxLength': '{{fieldName}} is too long',
    'Form Validation - min': '{{fieldName}} is too short',
    'Form Validation - pattern': '{{fieldName}} tidak sah',
    'Form Validation - validate': '{{fieldName}} tidak sah',
    'Form Validation - validate - minAge':
      'Birthday is invalid, should over {{minAge}} years old.',
    'Form Validation - terms':
      'Anda mesti bersetuju dengan terma dan syarat untuk meneruskan.',
    'Form Action Deleting': 'deleting',
    IAgreeTerms:
      'Saya bersetuju dengan syarat kedai <0>Terms of Use</0> and <2>Privacy Policy</2>',
    Terms: '<0>Terms of Use</0> and <2>Privacy Policy</2>',
    'Ask Signin': 'Sudah mendaftar?',
    'Member Login': 'Daftar masuk',
    Login: 'Daftar masuk',
    'Customer Information': 'Butiran Pelanggan',
    'Full Name': 'Nama Penuh',
    'Email Address': 'Alamat e-mel',
    'Create an account with shop': 'Mendaftar akaun dengan kedai {{shopName}}',
    'Send email updates and promotions':
      'I accept to receive shop updates, promotions and other marketing information from {{merchantName}}',
    'Shipping Address': 'Maklumat Penghantaran',
    'Recipient Name': 'Nama Penerima',
    'Recipient Contact Number': 'Nombor Telefon Penerima',
    'Address Line 1': 'Address',
    'Address Line 1 DE': 'Street and house number',
    'Address Line 2': 'Apartment, suite, etc. (Pilihan)',
    'Address Line 2 DE': 'Additional address (Pilihan)',
    Address: 'Address',
    'Shipping Destination': 'Shipping Destination',
    'US Shipping Destination': 'Country/Region',
    City: 'City',
    State: 'State/Province/Region',
    'US State': 'State',
    Zipcode: 'ZIP / Postal Code',
    'Zipcode GB': 'Postcode',
    'Zipcode FR': 'Postal Code',
    'Zipcode DE': 'Postal code',
    'Payment Detail': 'Butiran Pembayaran',
    'Payment Fee': 'Caj Pembayaran',
    'Total Order Over Hint':
      'This order must meet the minimum amount {{amount}} in order to proceed to checkout.',
    'Delivery Method': 'Kaedah Penghantaran',
    'Delivery Fee': 'Caj Penghantaran',
    'Remarks for Shop': 'Maklumat lain',
    'Remarks for Shop Placeholder':
      'Tinggalkan ringkasan pesanan untuk peniaga',
    'Cart Title': 'Product List',
    'Cart Total': 'Jumlah',
    'Cart Subtotals subtotal': 'Jumlah Kasar',
    'Cart Subtotals discount': 'Diskaun diberi',
    'Cart Subtotals total_tax_fee': 'Tax fee',
    'Cart Subtotals applied_user_credits': 'Applied Store Credit',
    'Cart Subtotals delivery_fee': 'Caj Penghantaran',
    'Cart Subtotals payment_fee': 'Caj Pembayaran',
    'Cart Subtotals custom_discount': 'Jumlah diskaun khas',
    'Cart delivery_fee Free': 'Free',
    'Cart delivery_fee Partial Free': 'Partial Free Shipping',
    'Place Order': 'Buat Pesanan',
    'Promotion Label - Promotion': 'Promosi',
    'Promotion Label - Subscription Promotion':
      'Product Subscription Promotion',
    'Promotion Label - Credit Reward': 'Reward Credits',
    'Promotion Label - Bundle Pricing': 'Bundle',
    'Promotion Label - Bundle Group': 'A + B',
    'Promotion Label - Buy X Get Y': 'Buy X Get Y',
    'Promotion Label - Member Point Redeem Gift': 'Points Campaign',
    'Promotion Label - Member Tier Promotion': 'Tawaran Ahli',
    'Custom Discount Item Label': 'Diskaun',
    'Max Discount Price custom_discount': 'sehingga',
    'Apply Code': 'Validate',
    'Enter Coupon Code': 'Enter coupon code',
    'Enter Affiliate Code': 'Apply An Affiliate Code',
    'Free Checkout': 'Free Checkout',
    'Form Error - Not Found Address':
      'Sorry, the address you have filled in is out of the delivery scope.',
    'Form Error - Not Found Delivery':
      'Sorry, the address you have filled in is out of the delivery scope.  Please choose another address.',
    'Form Error - Card Type Not Support':
      'The card type is not supported at this moment',
    'Credit Card Holder Name': 'Nama Penuh Pemilik Kad',
    'Credit Card Number': 'Nombor Kad Kredit',
    'Credit Card Expiry Date': 'Tarikh Luput (MM/YY)',
    'Credit Card CVC': 'CVC',
    'SlPayment Credit Card Holder Name Hint': 'Nama penuh sama pada kad',
    'SlPayment Credit Card Expiry Date': 'Tarikh Luput',
    'SlPayment Credit Card Expiry Date Month Placeholder': 'MM',
    'SlPayment Credit Card Expiry Date Year Placeholder': 'YY',
    'Payment General Error':
      'Error processing order, please verify your information and try again. If the problem persists, please email shop owner for details.',
    'Payment 3D Verification Failed':
      '3D secure verification failed, please try again or select other payment methods, thank you!',
    'CVC Hint': '3-digit security code usually found on the back of your card.',
    'Product Quantity Validation - Sold Out':
      'Sold out, please <1>remove it</1>',
    'Product Quantity Validation - Invalid Format': 'Must ≥ 1',
    'Product Quantity Validation - Low Inventory': 'Left {{ value }} only',
    'Product Quantity Validation - Purchase Limit':
      'Limit {{ value }} per order',
    'Product Quantity Validation - Quantity Over Inventory': 'Not enough stock',
    'Product Quantity Validation - Customer Purchase Limit Without Record':
      'Limit {{ limit }} per customer',
    'Product Quantity Validation - Customer Purchase Limit With Record':
      'Limit {{ limit }} per customer. You can only purchase {{ left }} more.',
    'Product Quantity Validation - Customer Purchase Limit Exceeded':
      'Limit {{ limit }} per customer. You have already purchased to limit.',
    'Product Quantity Validation - Invalid': 'Invalid, please <1>remove it</1>',
    'Remove Product Popup - title': 'Remove product',
    'Remove Product Popup - content':
      'Are you sure to remove {{ itemName }} from your shopping cart?',
    'Remove Product Popup - cancel': 'Cancel',
    'Remove Product Popup - remove': 'Remove',
    'Add-On Item Validation - Purchase Limit':
      'The quantity of add-on item should not over its main product',
    'Product(s) ordered from livestream are reserved':
      'Product(s) ordered from livestream are reserved,<0>{{ countdown }}</0>left to bring it home!',
    'Live Stream limited time reservation':
      'Live Stream limited time reservation {{ qty }} piece(s)',
    'Live Stream limited time reservation (separator)': ', ',
    'Live Stream limited time reservation (unit)': '{{qty}} piece(s)',
    'Tooltip date format': 'YYYY/MM/DD HH:mm',
    'Reserve till': `Reserve till {{ endDateFormat }}
      (Live streamed at {{ startDateFormat }}:
      {{ title }})
      Display according to your local time zone (GMT{{ offset }})`,
    'SL FPX Payment Hint':
      'Anda sedang menggunakan Pembayaran SHOPLINE untuk lengkapkan pembayaran sekarang. Dengan menyelesaikan pembayaran, anda telah mempersetujui <a href="/about/terms" target="_blank">Terma Perkhidmatan bagi Pembayaran</a> SHOPLINE dan <a href="https://www.mepsfpx.com.my/FPXMain/termsAndConditions.jsp" target="_blank">Terma Perkhidmatan</a> FPX.',
    'SL FPX Checkout Term':
      'Anda akan dibawa ke laman web bank untuk menyelesaikan pembayaran. Sila ingat untuk klik butang sahkan pembayaran untuk kembali ke laman pesanan anda bagi memastikan pesanan sudah dibuat.',
    'Field (optional)': '{{fieldName}} (Pilihan)',
    'Field - gender': 'Jantina',
    'Field - birthday': 'Tarikh Lahir',
    'Gender - male': 'Lelaki',
    'Gender - female': 'Perempuan',
    'Gender - other': 'Tidak diberitahu',
    'Saved Addresses - new': 'Alamat Baru',
    'Saved Addresses - default': 'Alamat Asal',
    'Saved Addresses - saved address': 'Alamat Yang Disimpan ',
    'Saved Addresses - save address': 'Simpan alamat ini dalam buku alamat',
    'Add To Cart': 'ADD TO CART',
    'Add To Cart Success': 'Item berjaya ditambah ke dalam troli anda',
    'Addon Reminder': "Don't miss out on our add-on products below!",
    'Addon Limit': 'LIMIT {{limit}} PER ORDER',
    'Reach Purchase Limit': 'REACH LIMIT',
    'Sold Out': 'SOLD OUT',
    'Addon Min Price': 'Get Add-on Items with purchase over {{minPrice}}',
    'Addon Item': 'Item Tambahan',
    'Cart Over Limit Error':
      'The maximum number of items is 100, please adjust the quantity and purchase again',
    Cancel: 'Cancel',
    Continue: 'Continue',
    'Auto Fill - Title': 'Enter your phone for fast checkout',
    'Auto Fill - Phone Error': 'Please enter the correct format',
    'Auto Fill - Toast':
      'If there is no record of the last order detected, the fast checkout data will not be brought in',
  },
  widget: {
    'Clear All Filter': 'Kosongkan semua',
  },
};
