import country from './country/id';

export default {
  translation: {
    ...country,
    'Express checkout': 'Express checkout',
    'Or fill in the information': 'Or fill in the information',
    'Form Validation - required': '{{fieldName}} is required',
    'Form Validation - max': '{{fieldName}} is too long',
    'Form Validation - maxLength': '{{fieldName}} is too long',
    'Form Validation - min': '{{fieldName}} is too short',
    'Form Validation - pattern': '{{fieldName}} is invalid',
    'Form Validation - validate': '{{fieldName}} is invalid',
    'Form Validation - validate - minAge':
      'Birthday is invalid, should over {{minAge}} years old.',
    'Form Validation - terms':
      "You must agree with the shop's Terms of Use and Privacy Policy",
    'Form Action Deleting': 'deleting',
    IAgreeTerms:
      'Saya setuju dengan <0>Ketentuan Penggunaan</0> dan <2>Kebijakan Privasi toko</2>',
    Terms: '<0>Ketentuan Penggunaan</0> dan <2>Kebijakan Privasi toko</2>',
    'Ask Signin': 'Sudah terdaftar?',
    'Member Login': 'Login',
    Login: 'Login',
    'Customer Information': 'Customer Info',
    'Full Name': 'Full Name',
    'Email Address': 'Email Address',
    'Email Address Optional': 'Email Address (optional)',
    'Create an account with shop': 'Create an account with {{shopName}} shop',
    'Send email updates and promotions':
      'I accept to receive shop updates, promotions and other marketing information from {{merchantName}}',
    'Shipping Address': 'Shipping Address',
    'Recipient Name': 'Recipient Name',
    'Recipient Contact Number': 'Phone Number',
    'Customer Contact Number': 'Customer contact number',
    'Address Line 1': 'Address',
    'Address Line 1 DE': 'Street and house number',
    'Address Line 2': 'Apartment, suite, etc. (optional)',
    'Address Line 2 DE': 'Additional address (optional)',
    Address: 'Address',
    'Shipping Destination': 'Shipping Destination',
    'US Shipping Destination': 'Country/Region',
    City: 'City',
    State: 'State/Province/Region',
    'US State': 'State',
    Zipcode: 'ZIP / Postal Code',
    'Zipcode GB': 'Postcode',
    'Zipcode FR': 'Postal Code',
    'Zipcode DE': 'Postal code',
    'Payment Detail': 'Payment Detail',
    'Payment Fee': 'Payment Fee',
    'Total Order Over Hint':
      'This order must meet the minimum amount {{amount}} in order to proceed to checkout.',
    'Delivery Method': 'Delivery Method',
    'Delivery Fee': 'Delivery Fee',
    'Remarks for Shop': 'Other Info',
    'Remarks for Shop Placeholder':
      'Leave order remarks for the Shop (optional)',
    'Cart Title': 'Product List',
    'Cart Total': 'Total',
    'Cart Subtotals subtotal': 'Item Subtotal',
    'Cart Subtotals discount': 'Discount Applied',
    'Cart Subtotals total_tax_fee': 'Tax fee',
    'Cart Subtotals applied_user_credits': 'Applied Store Credit',
    'Cart Subtotals delivery_fee': 'Delivery Fee',
    'Cart Subtotals payment_fee': 'Payment Fee',
    'Cart Subtotals custom_discount': 'Total Diskon Khusus',
    'Cart delivery_fee Free': 'Free',
    'Cart delivery_fee Partial Free': 'Partial Free Shipping',
    'Place Order': 'Place Order',
    'Promotion Label - Promotion': 'Promosi',
    'Promotion Label - Subscription Promotion':
      'Product Subscription Promotion',
    'Promotion Label - Credit Reward': 'Reward Credits',
    'Promotion Label - Bundle Pricing': 'Bundle',
    'Promotion Label - Bundle Group': 'A + B',
    'Promotion Label - Buy X Get Y': 'Buy X Get Y',
    'Promotion Label - Member Point Redeem Gift': 'Points Campaign',
    'Promotion Label - Member Tier Promotion': 'Penawaran Anggota',
    'Custom Discount Item Label': 'Diskon Khusus',
    'Max Discount Price custom_discount': 'hingga',
    'Apply Code': 'Validate',
    'Enter Coupon Code': 'Enter coupon code',
    'Enter Affiliate Code': 'Apply An Affiliate Code',
    'Free Checkout': 'Free Checkout',
    'Form Error - Not Found Address':
      'Sorry, the address you have filled in is out of the delivery scope.',
    'Form Error - Not Found Delivery':
      'Sorry, the address you have filled in is out of the delivery scope.  Please choose another address.',
    'Form Error - Card Type Not Support':
      'The card type is not supported at this moment',
    'Credit Card Holder Name': 'Cardholder Name',
    'Credit Card Number': 'Credit Card Number',
    'Credit Card Expiry Date': 'Expiry Date (MM/YY)',
    'Credit Card CVC': 'CVC',
    'SlPayment Credit Card Holder Name Hint': 'Same as the full name on card',
    'SlPayment Credit Card Expiry Date': 'Expiry Date',
    'SlPayment Credit Card Expiry Date Month Placeholder': 'MM',
    'SlPayment Credit Card Expiry Date Year Placeholder': 'YY',
    'Payment General Error':
      'Error processing order, please verify your information and try again. If the problem persists, please email shop owner for details.',
    'Payment 3D Verification Failed':
      '3D secure verification failed, please try again or select other payment methods, thank you!',
    'CVC Hint': '3-digit security code usually found on the back of your card.',
    'Product Quantity Validation - Sold Out':
      'Sold out, please <1>remove it</1>',
    'Product Quantity Validation - Invalid Format': 'Must ≥ 1',
    'Product Quantity Validation - Low Inventory': 'Left {{value}} only',
    'Product Quantity Validation - Purchase Limit': 'Limit {{value}} per order',
    'Product Quantity Validation - Quantity Over Inventory': 'Not enough stock',
    'Remove Product Popup - title': 'Remove product',
    'Remove Product Popup - content':
      'Are you sure to remove {{itemName}} from your shopping cart?',
    'Remove Product Popup - cancel': 'Cancel',
    'Remove Product Popup - remove': 'Remove',
    'Add-On Item Validation - Purchase Limit':
      'The quantity of add-on item should not over its main product',
    'Product(s) ordered from livestream are reserved':
      'Product(s) ordered from livestream are reserved,<0>{{ countdown }}</0>left to bring it home!',
    'Live Stream limited time reservation':
      'Live Stream limited time reservation {{ qty }} piece(s)',
    'Live Stream limited time reservation (separator)': ', ',
    'Live Stream limited time reservation (unit)': '{{qty}} piece(s)',
    'Tooltip date format': 'YYYY/MM/DD HH:mm',
    'Reserve till': `Reserve till {{ endDateFormat }}
      (Live streamed at {{ startDateFormat }}:
      {{ title }})
      Display according to your local time zone (GMT{{ offset }})`,
    'Inclusive Tax Hint':
      '{{taxName}} {{taxRate}}% Tax Included: {{taxAmount}}',
    'SL FPX Payment Hint':
      'You are using SHOPLINE Payments to checkout now. By completing the payment, you agree to the <a href="/about/terms" target="_blank">Terms of Service</a> of SHOPLINE Payments and <a href="https://www.mepsfpx.com.my/FPXMain/termsAndConditions.jsp" target="_blank">Terms of Services</a> of FPX',
    'SL FPX Checkout Term':
      "You will be redirected to the bank's website to finish the payment. Please remember to click the confirm payment button to return to the order page to make sure the order is placed.",
    'Select Bank': 'Select Bank',
    'Field (optional)': '{{fieldName}} (optional)',
    'Field - gender': 'Gender',
    'Field - birthday': 'Birthday',
    'Gender - male': 'Male',
    'Gender - female': 'Female',
    'Gender - other': 'Other',
    'Saved Addresses - new': 'Alamat Baru',
    'Saved Addresses - default': 'Alamat Default',
    'Saved Addresses - saved address': 'Alamat Yang Disimpan ',
    'Saved Addresses - save address':
      'Simpan alamat baru ini ke dalam buku alamat saya',
    'Add To Cart': 'ADD TO CART',
    'Add To Cart Success': 'Item berhasil ditambahkan ke keranjang Anda',
    'Addon Reminder': "Don't miss out on our add-on products below!",
    'Addon Limit': 'LIMIT {{limit}} PER ORDER',
    'Reach Purchase Limit': 'REACH LIMIT',
    'Sold Out': 'SOLD OUT',
    'Addon Min Price': 'Get Add-on Items with purchase over {{minPrice}}',
    'Addon Item': 'Item Add-on',
    'Cart Over Limit Error':
      'The maximum number of items is 100, please adjust the quantity and purchase again',
    Cancel: 'Cancel',
    Continue: 'Continue',
    'Auto Fill - Title': 'Enter your phone for fast checkout',
    'Auto Fill - Phone Error': 'Please enter the correct format',
    'Auto Fill - Toast':
      'If there is no record of the last order detected, the fast checkout data will not be brought in',
  },
  widget: {
    'Clear All Filter': 'Bersihkan semua',
  },
};
