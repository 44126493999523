export default {
  AC: 'เกาะอัสเซนชัน',
  AD: 'ราชรัฐอันดอร์รา',
  AE: 'สหรัฐอาหรับเอมิเรตส์',
  AF: 'อัฟกานิสถาน',
  AG: 'แอนติกาและบาร์บูดา',
  AI: 'แองกวิลลา',
  AL: 'แอลเบเนีย',
  AM: 'อาร์เมเนีย',
  AN: 'เนเธอร์แลนด์',
  AO: 'แองโกลา',
  AQ: 'แอนตาร์กติกา',
  AR: 'อาร์เจนตินา',
  AS: 'อเมริกันซามัว',
  AT: 'ออสเตรีย',
  AU: 'ออสเตรเลีย',
  AW: 'อารูบา',
  AX: 'หมู่เกาะโอลันด์',
  AZ: 'อาเซอร์ไบจาน',
  BA: 'บอสเนียและเฮอร์เซโก',
  BB: 'บาร์เบโดส',
  BD: 'บังคลาเทศ',
  BE: 'เบลเยียม',
  BF: 'บูร์กินาฟาโซ',
  BG: 'บัลแกเรีย',
  BH: 'บาห์เรน',
  BI: 'บุรุนดี',
  BJ: 'เบนิน',
  BL: 'เซนต์บาร์เธเลมี',
  BM: 'เบอร์มิวดา',
  BN: 'บรูไน',
  BO: 'โบลิเวีย',
  BQ: 'แคริบเบียนเนเธอร์แลนด์',
  BR: 'บราซิล',
  BS: 'บาฮามาส',
  BT: 'ภูฏาน',
  BV: 'เกาะบูเวต',
  BW: 'บอตสวานา',
  BY: 'เบลารุส',
  BZ: 'เบลีซ',
  CA: 'แคนาดา',
  CC: 'หมู่เกาะโคโคส [คีลิง]',
  CD: 'สาธารณรัฐประชาธิปไตยคองโก',
  CF: 'สาธารณรัฐอัฟริกากลาง',
  CG: 'สาธารณรัฐคองโก',
  CH: 'สวิสเซอร์แลนด์',
  CI: 'ไอวอรีโคสต์',
  CK: 'หมู่เกาะคุก',
  CL: 'ชิลี',
  CM: 'แคเมอรูน',
  CN: 'จีน',
  CO: 'โคลอมเบีย',
  CP: 'เกาะคลิปเปอร์ตัน',
  CR: 'คอสตาริกา',
  CU: 'คิวบา',
  CV: 'เคปเวิร์ด',
  CW: 'คูราเซา',
  CX: 'เกาะคริสต์มาส',
  CY: 'ไซปรัส',
  CZ: 'สาธารณรัฐเช็ก',
  DE: 'เยอรมัน',
  DG: 'ดิเอโกการ์เซีย',
  DJ: 'จิบูตี',
  DK: 'เดนมาร์ก',
  DM: 'โดมินิกา',
  DO: 'สาธารณรัฐโดมินิกัน',
  DZ: 'แอลจีเรีย',
  EA: 'เซวตาและเมลียา',
  EC: 'เอกวาดอร์',
  EE: 'เอสโตเนีย',
  EG: 'อียิปต์',
  EH: 'ซาฮาร่าตะวันตก',
  ER: 'เอริเทรีย',
  ES: 'สเปน',
  ET: 'สาธารณรัฐเอธิโอเปีย',
  EU: 'สหภาพยุโรป',
  FI: 'ฟินแลนด์',
  FJ: 'ฟิจิ',
  FK: 'หมู่เกาะฟอล์กแลนด์ [หมู่เกาะมัลบีนัส]',
  FM: 'ไมโครนีเซีย',
  FO: 'หมู่เกาะแฟโร',
  FR: 'ฝรั่งเศส',
  GA: 'กาบอง',
  GB: 'สหราชอาณาจักร',
  GD: 'เกรเนดา',
  GE: 'จอร์เจีย',
  GF: 'กีอาน่าฝรั่งเศส',
  GG: 'เกาะเกิร์นซีย์',
  GH: 'กานา',
  GI: 'ยิบรอลตาร์',
  GL: 'เกาะกรีนแลนด์',
  GM: 'แกมเบีย',
  GN: 'กินี',
  GP: 'กัวเดอลุป',
  GQ: 'อิเควทอเรียลกินี',
  GR: 'กรีซ',
  GS: 'เกาะเซาท์จอร์เจีย และ หมู่เกาะเซาท์แซนด์วิช',
  GT: 'กัวเตมาลา',
  GU: 'กวม',
  GW: 'กินี-บิสเซา',
  GY: 'กายอานา',
  HK: 'ฮ่องกง',
  HM: 'เกาะเฮิร์ดและหมู่เกาะแมกดอนัลด์',
  HN: 'ฮอนดูรัส',
  HR: 'โครเอเชีย',
  HT: 'เฮติ',
  HU: 'ฮังการี',
  IC: 'หมู่เกาะคะเนรี',
  ID: 'อินโดนีเซีย',
  IE: 'ไอร์แลนด์',
  IL: 'อิสราเอล',
  IM: 'ไอล์ออฟแมน',
  IN: 'อินเดีย',
  IO: 'บริติชอินเดียนโอเชียนเทร์ริทอรี',
  IQ: 'อิรัก',
  IR: 'อิหร่าน',
  IS: 'ไอซ์แลนด์',
  IT: 'อิตาลี',
  JE: 'เจอร์ซีย์',
  JM: 'จาเมกา',
  JO: 'จอร์แดน',
  JP: 'ญี่ปุ่น',
  KE: 'เคนยา',
  KG: 'คีร์กีซสถาน',
  KH: 'กัมพูชา',
  KI: 'คิริบาส',
  KM: 'คอโมโรส',
  KN: 'สหพันธรัฐเซนต์คิตส์และเนวิส',
  KP: 'เกาหลีเหนือ',
  KR: 'เกาหลีใต้',
  KW: 'คูเวต',
  KY: 'หมู่เกาะเคย์แมน',
  KZ: 'คาซัคสถาน',
  LA: 'ลาว',
  LB: 'เลบานอน',
  LC: 'เซนต์ลูเซีย',
  LI: 'ลิกเตนสไตน์',
  LK: 'ศรีลังกา',
  LR: 'ไลบีเรีย',
  LS: 'เลโซโท',
  LT: 'ลิทัวเนีย',
  LU: 'ลักเซมเบิร์ก',
  LV: 'ลัตเวีย',
  LY: 'ลิเบีย',
  MA: 'โมร็อกโก',
  MC: 'โมนาโก',
  MD: 'มอลโดวา',
  ME: 'มอนเตเนโกร',
  MF: 'เซนต์มาร์ติน',
  MG: 'มาดากัสการ์',
  MH: 'หมู่เกาะมาร์แชลล์',
  MK: 'มาซิโดเนียเหนือ',
  ML: 'มาลี',
  MM: 'เมียนมาร์ (พม่า)',
  MN: 'มองโกเลีย',
  MO: 'Macau',
  MP: 'หมู่เกาะนอร์เทิร์นมาเรียนา',
  MQ: 'มาร์ตีนิก',
  MR: 'มอริเตเนีย',
  MS: 'มอนต์เซอร์รัต',
  MT: 'มอลตา',
  MU: 'มอริเชียส',
  MV: 'มัลดีฟส์',
  MW: 'มาลาวี',
  MX: 'เม็กซิโก',
  MY: 'มาเลเซีย',
  MZ: 'โมซัมบิก',
  NA: 'นามิเบีย',
  NC: 'นิวแคลิโดเนีย',
  NE: 'ไนเจอร์',
  NF: 'เกาะนอร์ฟอล์ก',
  NG: 'ไนจีเรีย',
  NI: 'นิการากัว',
  NL: 'เนเธอร์แลนด์',
  NO: 'นอร์เวย์',
  NP: 'เนปาล',
  NR: 'นาอูรู',
  NU: 'นีอูเอ',
  NZ: 'นิวซีแลนด์',
  OM: 'โอมาน',
  PA: 'ปานามา',
  PE: 'เปรู',
  PF: 'เฟรนช์โปลินีเซีย',
  PG: 'ปาปัวนิวกินี',
  PH: 'ฟิลิปปินส์',
  PK: 'ปากีสถาน',
  PL: 'โปแลนด์',
  PM: 'แซงปีแยร์และมีเกอลง',
  PN: 'หมู่เกาะพิตแคร์น',
  PR: 'เปอร์โตริโก',
  PS: 'ดินแดนปาเลสไตน์',
  PT: 'โปรตุเกส',
  PW: 'ปาเลา',
  PY: 'ปารากวัย',
  QA: 'กาตาร์',
  QO: 'โอเชียเนีย',
  RE: 'เรอูนียง',
  RO: 'โรมาเนีย',
  RS: 'เซอร์เบีย',
  RU: 'รัสเซีย',
  RW: 'รวันดา',
  SA: 'ซาอุดีอาระเบีย',
  SB: 'หมู่เกาะโซโลมอน',
  SC: 'เซเชลส์',
  SD: 'ซูดาน',
  SE: 'สวีเดน',
  SG: 'สิงคโปร์',
  SH: 'เซนต์เฮเลนา',
  SI: 'สโลวีเนีย',
  SJ: 'สฟาลบาร์และยานไมเอน',
  SK: 'สโลวาเกีย',
  SL: 'เซียร์ราลีโอน',
  SM: 'ซานมารีโน',
  SN: 'เซเนกัล',
  SO: 'โซมาเลีย',
  SR: 'ซูรินาม',
  SS: 'เซาท์ซูดาน',
  ST: 'เซาตูเมและปรินซีปี',
  SV: 'เอลซัลวาดอร์',
  SX: 'เกาะเซนต์มาร์ติน',
  SY: 'ซีเรีย',
  SZ: 'สวาซิแลนด์',
  TA: 'ตริสตันดากูนยา',
  TC: 'หมู่เกาะเติกส์และหมู่เกาะเคคอส',
  TD: 'ชาด',
  TF: 'เฟรนช์เซาเทิร์นเทร์ริทอรีส์',
  TG: 'โตโก',
  TH: 'ประเทศไทย',
  TJ: 'ทาจิกิสถาน',
  TK: 'โตเกเลา',
  TL: 'ติมอร์ตะวันออก',
  TM: 'เติร์กเมนิสถาน',
  TN: 'ตูนิเซีย',
  TO: 'ตองกา',
  TR: 'ตุรกี',
  TT: 'ตรินิแดดและโตเบโก',
  TV: 'ตูวาลู',
  TW: 'ไต้หวัน',
  TZ: 'แทนซาเนีย',
  UA: 'ยูเครน',
  UG: 'ยูกันดา',
  UM: 'สหรัฐอเมริกาหมู่เกาะรอบนอก',
  US: 'สหรัฐอเมริกา',
  UY: 'อุรุกวัย',
  UZ: 'อุซเบกิสถาน',
  VA: 'นครรัฐวาติกัน',
  VC: 'เซนต์วินเซนต์และเกรนาดีนส์',
  VE: 'เวเนซุเอลา',
  VG: 'หมู่เกาะบริติชเวอร์จิน',
  VI: 'หมู่เกาะเวอร์จินของสหรัฐอเมริกา',
  VN: 'เวียดนาม',
  VU: 'วานูอาตู',
  WF: 'วาลลิสและฟุตูนา',
  WS: 'ซามัว',
  YE: 'เยเมน',
  YT: 'มายอต',
  ZA: 'แอฟริกาใต้',
  ZM: 'แซมเบีย',
  ZW: 'ซิมบับเว',
  ZZ: 'Unknown Region',
};
