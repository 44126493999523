export default {
  AC: '阿森鬆島',
  AD: '安道爾',
  AE: '阿拉伯聯合酋長國',
  AF: '阿富汗',
  AG: '安提瓜和巴布達',
  AI: '安圭拉',
  AL: '阿爾巴尼亞',
  AM: '亞美尼亞',
  AN: '荷屬安的列斯群島',
  AO: '安哥拉',
  AQ: '南極洲',
  AR: '阿根廷',
  AS: '美屬薩摩亞',
  AT: '奧地利',
  AU: '澳大利亞',
  AW: '阿魯巴',
  AX: '奧蘭群島',
  AZ: '阿塞拜疆',
  BA: '波斯尼亞和黑塞哥維那',
  BB: '巴巴多斯',
  BD: '孟加拉國',
  BE: '比利時',
  BF: '布基納法索',
  BG: '保加利亞',
  BH: '巴林',
  BI: '布隆迪',
  BJ: '貝寧',
  BL: '聖巴泰勒米',
  BM: '百慕大',
  BN: '文萊',
  BO: '玻利維亞',
  BQ: '荷蘭加勒比',
  BR: '巴西',
  BS: '巴哈馬',
  BT: '不丹',
  BV: '布維特島',
  BW: '博茨瓦納',
  BY: '白俄羅斯',
  BZ: '伯利茲',
  CA: '加拿大',
  CC: '科科斯群島',
  CD: '剛果民主共和國',
  CF: '中非共和國',
  CG: '剛果共和國',
  CH: '瑞士',
  CI: '象牙海岸',
  CK: '庫克群島',
  CL: '智利',
  CM: '喀麥隆',
  CN: '中國',
  CO: '哥倫比亞',
  CP: '克利珀頓島',
  CR: '哥斯達黎加',
  CU: '古巴',
  CV: '佛得角',
  CW: '庫拉索',
  CX: '聖誕島',
  CY: '塞浦路斯',
  CZ: '捷克共和國',
  DE: '德國',
  DG: '迪戈加西亞島',
  DJ: '吉布提',
  DK: '丹麥',
  DM: '多米尼加',
  DO: '多米尼加共和國',
  DZ: '阿爾及利亞',
  EA: '休達及梅利利亞',
  EC: '厄瓜多爾',
  EE: '愛沙尼亞',
  EG: '埃及',
  EH: '西撒哈拉',
  ER: '厄立特裡亞',
  ES: '西班牙',
  ET: '埃塞俄比亞',
  EU: '歐盟',
  FI: '芬蘭',
  FJ: '斐濟',
  FK: '福克蘭群島（馬爾維納斯群島）',
  FM: '密克羅尼西亞聯邦',
  FO: '法羅群島',
  FR: '法國',
  GA: '加蓬',
  GB: '英國',
  GD: '格林納達',
  GE: '格魯吉亞',
  GF: '法屬圭亞那',
  GG: '根西島',
  GH: '加納',
  GI: '直布羅陀',
  GL: '格陵蘭',
  GM: '岡比亞',
  GN: '幾內亞',
  GP: '瓜德羅普島',
  GQ: '赤道幾內亞',
  GR: '希臘',
  GS: '南喬治亞島和南桑威齊群島',
  GT: '危地馬拉',
  GU: '關島',
  GW: '幾內亞比紹',
  GY: '圭亞那',
  HK: '香港',
  HM: '赫德與麥克唐納群島',
  HN: '洪都拉斯',
  HR: '克羅地亞',
  HT: '海地',
  HU: '匈牙利',
  IC: '加納利群島',
  ID: '印度尼西亞',
  IE: '愛爾蘭',
  IL: '以色列',
  IM: '曼島',
  IN: '印度',
  IO: '英屬印度洋領地',
  IQ: '伊拉克',
  IR: '伊朗',
  IS: '冰島',
  IT: '意大利',
  JE: '澤西島',
  JM: '牙買加',
  JO: '約旦',
  JP: '日本',
  KE: '肯尼亞',
  KG: '吉爾吉斯斯坦',
  KH: '柬埔寨',
  KI: '基裡巴斯',
  KM: '科摩羅',
  KN: '聖基茨和尼維斯',
  KP: '朝鮮',
  KR: '韓國',
  KW: '科威特',
  KY: '開曼群島',
  KZ: '哈薩克斯坦',
  LA: '老撾',
  LB: '黎巴嫩',
  LC: '聖盧西亞',
  LI: '列支敦士登',
  LK: '斯裡蘭卡',
  LR: '利比裡亞',
  LS: '萊索托',
  LT: '立陶宛',
  LU: '盧森堡',
  LV: '拉脫維亞',
  LY: '利比亞',
  MA: '摩洛哥',
  MC: '摩納哥',
  MD: '摩爾多瓦',
  ME: '黑山共和國',
  MF: '法屬聖馬丁',
  MG: '馬達加斯加',
  MH: '馬紹爾群島',
  MK: '馬其頓',
  ML: '馬裡',
  MM: '緬甸',
  MN: '蒙古',
  MO: '澳門',
  MP: '北馬裡亞納群島',
  MQ: '馬提尼克',
  MR: '毛裡塔尼亞',
  MS: '蒙塞拉特',
  MT: '馬耳他',
  MU: '毛裡求斯',
  MV: '馬爾代夫',
  MW: '馬拉維',
  MX: '墨西哥',
  MY: '馬來西亞',
  MZ: '莫桑比克',
  NA: '納米比亞',
  NC: '新喀裡多尼亞',
  NE: '尼日爾',
  NF: '諾福克島',
  NG: '尼日利亞',
  NI: '尼加拉瓜',
  NL: '荷蘭',
  NO: '挪威',
  NP: '尼泊爾',
  NR: '瑙魯',
  NU: '紐埃',
  NZ: '新西蘭',
  OM: '阿曼',
  PA: '巴拿馬',
  PE: '秘魯',
  PF: '法屬波利尼西亞',
  PG: '巴布亞新幾內亞',
  PH: '菲律賓',
  PK: '巴基斯坦',
  PL: '波蘭',
  PM: '聖皮埃爾和密克隆群島',
  PN: '皮特凱恩群島',
  PR: '波多黎各',
  PS: '巴勒斯坦領土',
  PT: '葡萄牙',
  PW: '帕勞',
  PY: '巴拉圭',
  QA: '卡塔爾',
  QO: '大洋洲邊遠群島',
  RE: '留尼汪',
  RO: '羅馬尼亞',
  RS: '塞爾維亞',
  RU: '俄羅斯',
  RW: '盧旺達',
  SA: '沙特阿拉伯',
  SB: '所羅門群島',
  SC: '塞舌爾',
  SD: '蘇丹',
  SE: '瑞典',
  SG: '新加坡',
  SH: '聖赫勒拿',
  SI: '斯洛文尼亞',
  SJ: '斯瓦爾巴特和揚馬延',
  SK: '斯洛伐克',
  SL: '塞拉利昂',
  SM: '聖馬力諾',
  SN: '塞內加爾',
  SO: '索馬裡',
  SR: '蘇裡南',
  SS: '南蘇丹',
  ST: '聖多美和普林西比',
  SV: '薩爾瓦多',
  SX: '荷屬聖馬丁',
  SY: '敘利亞',
  SZ: '斯威士蘭',
  TA: '特裡斯坦-達庫尼亞群島',
  TC: '特克斯和凱科斯群島',
  TD: '乍得',
  TF: '法屬南部領土',
  TG: '多哥',
  TH: '泰國',
  TJ: '塔吉克斯坦',
  TK: '托克勞',
  TL: '東帝汶',
  TM: '土庫曼斯坦',
  TN: '突尼斯',
  TO: '湯加',
  TR: '土耳其',
  TT: '特立尼達和多巴哥',
  TV: '圖瓦盧',
  TW: '台灣',
  TZ: '坦桑尼亞',
  UA: '烏克蘭',
  UG: '烏干達',
  UM: '美國邊遠小島',
  US: '美國',
  UY: '烏拉圭',
  UZ: '烏茲別克斯坦',
  VA: '梵蒂岡',
  VC: '聖文森特和格林納丁斯',
  VE: '委內瑞拉',
  VG: '英屬維京群島',
  VI: '美屬維京群島',
  VN: '越南',
  VU: '瓦努阿圖',
  WF: '瓦利斯和富圖納',
  WS: '薩摩亞',
  YE: '也門',
  YT: '馬約特',
  ZA: '南非',
  ZM: '贊比亞',
  ZW: '津巴布韋',
  ZZ: '未知地區',
};
